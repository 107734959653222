import * as React from "react"

const HeaderLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"

    width={270}
    height={134.31}
    viewBox="0 0 270 134.31"
    {...props}
  >
    <title>Logo</title>
    <defs>
      <style>{".cls-1{fill:#234D5A;stroke-width:0}"}</style>
    </defs>
    <g id="artwork">
      <path
        d="M45.92 0H26.57L0 56.97h15.22L20.8 45h46.1L45.92 0ZM26.98 31.74l9.26-19.86 9.26 19.86H26.98Z"
        style={{
          fill: "#f45516",
          strokeWidth: 0,
        }}
      />
      <path
        d="M261.2 56.98c.12.18.27.31.46.4.19.08.38.13.58.13.11 0 .22-.02.34-.05.11-.03.22-.08.32-.15s.17-.16.23-.26.09-.22.09-.36c0-.19-.06-.33-.18-.43-.12-.1-.27-.18-.45-.25s-.37-.13-.58-.19c-.21-.06-.4-.15-.58-.26-.18-.11-.33-.26-.45-.44-.12-.19-.18-.44-.18-.76 0-.14.03-.3.09-.46s.16-.31.3-.44c.13-.13.31-.25.51-.34.21-.09.46-.14.76-.14.27 0 .52.04.77.11.24.07.46.22.64.45l-.54.49a.884.884 0 0 0-.35-.31c-.15-.08-.33-.12-.52-.12-.18 0-.34.02-.46.07a.77.77 0 0 0-.45.44.75.75 0 0 0-.04.24c0 .21.06.37.18.48.12.11.27.2.45.27s.37.13.58.19c.21.05.4.13.58.23.18.1.33.23.45.4s.18.4.18.7a1.468 1.468 0 0 1-1.04 1.42c-.21.07-.43.1-.66.1-.31 0-.61-.05-.89-.17-.28-.11-.5-.28-.67-.51l.55-.47ZM264.85 53.11h.99l1.56 3.71h.03l1.54-3.71h.99v4.9h-.66v-4.03h-.01l-1.65 4.03h-.44l-1.65-4.03h-.01v4.03h-.67v-4.9ZM240.18 55.64c-16.44 0-29.82 13.38-29.82 29.82s13.38 29.82 29.82 29.82S270 101.9 270 85.46s-13.38-29.82-29.82-29.82Zm15.47 29.82c0 8.53-6.94 15.46-15.47 15.46s-15.47-6.94-15.47-15.46 6.94-15.47 15.47-15.47 15.47 6.94 15.47 15.47ZM173.55 85.46c0 8.53 6.94 15.47 15.47 15.47 4.27 0 8.14-1.73 11.19-4.69l10.1 10.1c-5.67 5.74-13.18 8.95-21.28 8.95-16.44 0-29.82-13.38-29.82-29.82s13.38-29.82 29.82-29.82c8.11 0 15.61 3.21 21.28 8.95l-10.1 10.1c-3.04-2.97-6.92-4.69-11.19-4.69-8.53 0-15.47 6.94-15.47 15.47ZM75.72 56.97l-1.82 3a29.634 29.634 0 0 0-15.47-4.34c-16.44 0-29.82 13.38-29.82 29.82s13.38 29.82 29.82 29.82c5.66 0 10.96-1.59 15.47-4.34l1.82 3h11.97V56.95H75.72Zm-17.3 43.95c-8.53 0-15.47-6.94-15.47-15.46s6.94-15.47 15.47-15.47 15.47 6.94 15.47 15.47-6.94 15.46-15.47 15.46ZM124.08 55.64a29.72 29.72 0 0 0-15.47 4.33l-1.82-3H94.82v77.34h14.35v-23.03c4.39 2.54 9.48 4 14.91 4 16.44 0 29.82-13.38 29.82-29.82s-13.38-29.82-29.82-29.82Zm0 45.28c-8.53 0-15.47-6.94-15.47-15.46s6.94-15.47 15.47-15.47 15.47 6.94 15.47 15.47-6.94 15.46-15.47 15.46Z"
        style={{
          fill: "#264B5A",
          strokeWidth: 0,
        }}
      />
    </g>
  </svg>
)
export default HeaderLogo;
