import * as React from "react"
const InstagramLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44.29}
    height={44.29}
    viewBox="0 0 44.29 44.29"
    {...props}
  >
    <title>Instagram</title>
    <path
      d="M14.76 22.14c0-4.08 3.3-7.38 7.38-7.38s7.38 3.31 7.38 7.38-3.31 7.38-7.38 7.38-7.38-3.31-7.38-7.38m-3.99 0c0 6.28 5.09 11.37 11.37 11.37s11.37-5.09 11.37-11.37-5.09-11.37-11.37-11.37-11.37 5.09-11.37 11.37M31.3 10.32c0 1.47 1.19 2.66 2.66 2.66s2.66-1.19 2.66-2.66-1.19-2.66-2.66-2.66-2.66 1.19-2.66 2.66M13.19 40.17c-2.16-.1-3.33-.46-4.11-.76-1.03-.4-1.77-.88-2.55-1.66-.77-.77-1.26-1.51-1.66-2.55-.3-.78-.66-1.95-.76-4.11-.11-2.33-.13-3.04-.13-8.95s.02-6.61.13-8.95c.1-2.16.46-3.33.76-4.11.4-1.03.88-1.77 1.66-2.55.77-.77 1.51-1.26 2.55-1.66.78-.3 1.95-.66 4.11-.76 2.33-.11 3.04-.13 8.95-.13s6.61.02 8.95.13c2.16.1 3.33.46 4.11.76 1.03.4 1.77.88 2.55 1.66.77.77 1.25 1.51 1.66 2.55.3.78.66 1.95.76 4.11.11 2.34.13 3.04.13 8.95s-.02 6.61-.13 8.95c-.1 2.16-.46 3.33-.76 4.11-.4 1.03-.88 1.77-1.66 2.55S36.24 39 35.2 39.41c-.78.3-1.95.66-4.11.76-2.33.11-3.04.13-8.95.13s-6.61-.02-8.95-.13M13.01.13c-2.36.11-3.97.48-5.37 1.03-1.46.57-2.69 1.32-3.92 2.55-1.23 1.23-1.99 2.46-2.55 3.92C.62 9.04.25 10.65.14 13c-.11 2.36-.13 3.12-.13 9.13s.03 6.77.13 9.13c.11 2.36.48 3.97 1.03 5.37.57 1.46 1.32 2.69 2.55 3.92 1.23 1.23 2.46 1.99 3.92 2.55 1.41.55 3.02.92 5.37 1.03 2.36.11 3.12.13 9.13.13s6.77-.03 9.13-.13c2.36-.11 3.97-.48 5.37-1.03 1.46-.57 2.69-1.32 3.92-2.55 1.23-1.23 1.99-2.47 2.55-3.92.55-1.41.92-3.02 1.03-5.37.11-2.36.13-3.12.13-9.13s-.03-6.77-.13-9.13c-.11-2.36-.48-3.97-1.03-5.37-.57-1.46-1.32-2.69-2.55-3.92a10.814 10.814 0 0 0-3.92-2.55C35.23.61 33.62.24 31.27.13 28.91.02 28.15 0 22.14 0s-6.77.03-9.13.13"
      style={{
        fill: "#fff",
        strokeWidth: 0,
      }}
    />
  </svg>
)
export default InstagramLogo;
