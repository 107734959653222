import * as React from "react"
const LinkedinLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"

    width={44.41}
    height={44.29}
    viewBox="0 0 44.41 44.29"
    {...props}
  >
    <title>Linkedin</title>
    <g id="artwork">
      <path
        d="M24.56 18.66v-.34c0-1.09-.01-2.18 0-3.26 0-.3-.07-.39-.38-.39-2.7.01-5.41.01-8.11 0-.34 0-.43.11-.43.43v28.76c0 .36.12.44.45.43 2.77-.01 5.54 0 8.31 0q.53 0 .53-.55V30.03c0-1.36.02-2.72.36-4.05.4-1.56 1.18-2.83 2.75-3.46.9-.36 1.84-.42 2.8-.37 1.65.09 2.87.84 3.55 2.38.5 1.14.66 2.36.67 3.58.03 5.22.01 10.44.01 15.67q0 .51.49.51h8.28q.53 0 .53-.55c0-4.53.02-9.07 0-13.6-.01-1.94-.08-3.89-.19-5.83-.08-1.48-.39-2.92-.89-4.31-.9-2.5-2.5-4.35-5.05-5.27-2.33-.84-4.73-1.02-7.15-.57-2.09.39-3.9 1.35-5.34 2.94-.43.48-.79 1.02-1.21 1.56ZM.73 29.51v14.36c0 .33.09.42.42.42 2.83-.01 5.66-.01 8.48 0 .34 0 .42-.11.42-.43V15.11c0-.34-.1-.45-.45-.44-2.79.01-5.59 0-8.38 0q-.49 0-.49.48v14.36Zm4.61-18.77c2.99 0 5.39-2.37 5.4-5.33C10.77 2.65 8.63.01 5.38 0 2.34-.01 0 2.37 0 5.39c0 3.15 2.57 5.38 5.34 5.35Z"
        className="cls-1"
        style={{
          fill: "#fff",
          strokeWidth: 0,
        }}

      />
      <path
        d="M24.56 18.66c.42-.54.78-1.09 1.21-1.56 1.44-1.59 3.25-2.56 5.34-2.94 2.43-.45 4.83-.27 7.15.57 2.54.92 4.15 2.76 5.05 5.27.5 1.39.81 2.84.89 4.31.1 1.94.18 3.88.19 5.83.03 4.53 0 9.07 0 13.6q0 .55-.53.55h-8.28q-.49 0-.49-.51c0-5.22.01-10.44-.01-15.67 0-1.22-.17-2.44-.67-3.58-.68-1.54-1.9-2.29-3.55-2.38-.96-.05-1.9 0-2.8.37-1.57.63-2.34 1.9-2.75 3.46-.34 1.33-.36 2.69-.36 4.05v13.71q0 .55-.53.55h-8.31c-.34 0-.45-.08-.45-.43V15.1c0-.32.09-.43.43-.43 2.7.01 5.41.01 8.11 0 .31 0 .39.09.38.39-.02 1.09 0 2.18 0 3.26v.34ZM.73 29.51V15.15q0-.48.49-.48H9.6c.35 0 .45.1.45.44v28.75c0 .32-.09.43-.42.43-2.83-.01-5.66-.01-8.48 0-.33 0-.42-.09-.42-.42.01-4.79 0-9.57 0-14.36Z"
        className="cls-1"
        style={{
          fill: "#fff",
          strokeWidth: 0,
        }}
      />
    </g>
  </svg>
)
export default LinkedinLogo;
