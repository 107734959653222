import * as React from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import HeaderLogo from "../svgs/header-logo";
import HeaderMobileLogo from "../svgs/a-logo";
import "./header.scss";


export default function Header({location, dataProps}) {

	React.useEffect(() => {
		document.getElementById('form-search').addEventListener('submit', submitForm);
	},[]);





	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
			wpMenu(name: {eq: "Primary Menu"}) {
				menuItems {
					nodes {
						title
						url
						label
						parentId
					}
				}
			}
			hamburgerMenu:  wpMenu(name: {eq: "Hamburger Menu"}) {
		    menuItems {
		      nodes {
		        title
		        url
		        label
		        parentId
		        childItems {
		          nodes {
		            url
		            label
		            parentId
		          }
		        }
		      }
		    }
		  }
			allWpMenu(
				filter: {name: {in: ["Header Menu 1", "Header Menu 2", "Header Menu 3"]}}
				sort: {name: ASC}
			  ) {
				edges {
				  node {
					name
					menuItems {
					  nodes {
							url
							label
							parentId
		          childItems {
	              nodes {
	                url
	                label
	                parentId
	              }
	            }
					  }
					}
					databaseId
				  }
				}
			}
		}
	`);

	const dataLayerPushEvent = eventObject => {
		if (window) {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push(eventObject)
		}
	}

	function submitForm(event){
		event.preventDefault();
		dataLayerPushEvent({
			'event': 'searchQuery',
			'searchQuery': event.target.search.value
		});

		navigate(`/search?search=${encodeURIComponent(event.target.search.value)}`);
	}

	const topLevelMenuItems = data?.hamburgerMenu?.menuItems.nodes.filter(item => item.parentId === null).slice(0,4);

	return (
		<header className="site-header">
			<div data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top; cls-active: uk-navbar-sticky;" className="uk-sticky">
				<div className="container">
					<div className="header-wrp">
						<div className="site-branding">
							<Link className="desktop-logo" aria-label="Logo" to="/">
								<HeaderLogo />
							</Link>
							<Link className="mobile-logo" aria-label="Logo Mobile" to="/">
								<HeaderMobileLogo />
							</Link>
						</div>
						<nav className="main-navigation">
							<ul>
								{data?.wpMenu?.menuItems?.nodes.length > 0 && data?.wpMenu?.menuItems?.nodes.map((e, index) => (
									<li key={index}>
										<Link className="font-avenir-medium" to={e.url} aria-label={`Go to ${e.label}`} activeClassName="active">{e.label}</Link>
									</li>
								))}
							</ul>
						</nav>
						<div className="mobile-navigation uk-flex">
							{!dataProps?.wpPage?.isFrontPage && (dataProps?.wpPage?.title || dataProps?.wpService?.title || dataProps?.wpSector?.title || dataProps?.wpSolution?.title || dataProps?.wpPeople?.title ) &&
								<span className="font-avenir-medium">
									{'/ ' + (dataProps?.wpPage?.title || dataProps?.wpService?.title || dataProps?.wpSector?.title || dataProps?.wpSolution?.title || dataProps?.wpPeople?.title)}
								</span>
							}
							<div className="hamburger-menu" data-uk-toggle="target: #hamburger-menu-modal">
								<span />
								<span />
								<span />
								<span />
							</div>
						</div>

					</div>
				</div>
			</div>
			<div id="hamburger-menu-modal" data-uk-modal data-uk-modal="true" style={{display:'none'}}>
				<div className="uk-modal-dialog uk-modal-body">
					<div className="uk-modal-close-default ci-close-btn" data-uk-close></div>
					<div className="has-a-deco">
						<div className="uk-grid" data-uk-grid>
							{/*data?.allWpMenu?.edges && data?.allWpMenu?.edges.length > 0 && data?.allWpMenu?.edges.map((e, index) => (
								<ul key={index} className="uk-width-1-3@m">
									{e?.node?.menuItems?.nodes.length > 0 && e?.node?.menuItems?.nodes.map((item, itemIndex) => (
										<React.Fragment key={itemIndex}>
											{item.url.startsWith("#") ?
												<li className="font-avenir-medium" data-uk-toggle={`target: ${item.url}`} >
													<Link to={'#'} aria-label={item.label} className="font-avenir-medium" >{item.label}</Link>
												</li>
												:
												<li className="font-avenir-medium">
													<Link to={item.url} aria-label={item.label} activeClassName="active" className="font-avenir-medium">{item.label}</Link>
												</li>
											}
										</React.Fragment>
									))}
								</ul>
							))*/}
							{topLevelMenuItems && topLevelMenuItems?.length > 0 && topLevelMenuItems?.map((e, index) => (
								<ul key={index} className={`uk-width-1-${topLevelMenuItems.length}@m`}>
									<React.Fragment key={index}>
										{e.parentId === null ?
											<>
												<li className="font-avenir-medium">
													<Link to={e.url} aria-label={e.label} activeClassName="active" className="font-avenir-medium">{e.label}</Link>
												</li>
												{e?.childItems?.nodes && e?.childItems?.nodes?.length > 0 && e?.childItems?.nodes?.map((child, i) => (
													<li className="child">
														<Link to={child.url} aria-label={child.label} activeClassName="active" className="font-avenir-medium">{child.label}</Link>
													</li>
												))}
											</>
											:
											null
										}
									</React.Fragment>
								</ul>
							))}
							<div className="uk-width-1-1">
								{/*<form className="search-form" action="/search" onSubmit={submitForm}>*/}
								<form className="search-form" id="form-search" onSubmit={e => submitForm(e)}>
									<input type="text" name="search" placeholder="Search" />
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}
