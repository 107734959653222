import * as React from "react";

const ALogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={88.82}
    height={75.79}
    viewBox="0 0 88.82 75.79"
  >
    <path
      d="M60.93 0H35.34L0 75.79h20.12l7.46-15.99h61.25L60.93 0Zm-.73 41.57H36.07L48.14 15.7 60.2 41.57Z"
      style={{
        fill: props.fillColor ? props.fillColor : "#f45516",
        strokeWidth: 0,
      }}
    />
  </svg>
)
export default ALogo;
