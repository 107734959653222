// UIkit
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/css/uikit-core.min.css";
// Custom style
import "./src/styles/scss/main.scss";
import InnerHTML from 'dangerously-set-html-content';
// import function to register Swiper custom elements
// import { register } from 'swiper/element/bundle';
// // register Swiper custom elements
// register();

const React = require("react")
const Layout = require("./src/templates/layout").default

export const wrapPageElement = ({ element, props }) => {

	return <Layout {...props}>{element}</Layout>

}

// export const onInitialClientRender = () => {

// 	const Uikit = require("uikit/dist/js/uikit.min");
// 	console.log(Uikit);

// }

export const onInitialClientRender = () => {

	// import("uikit/dist/js/uikit.min").then(() => {
	// 	console.log('Uikit loaded');
	// });

	const script = document.createElement("script");
	script.src = "/uikit/dist/js/uikit.min.js";
	script.async = false; // Ensures the script is executed in order
	script.defer = true;  // Helps with load order priority
	document.body.appendChild(script);

	const script1 = document.createElement('script');
	script1.src = "https://cdn.cookielaw.org/consent/018e9ff9-68c2-7c8b-964a-b2c3c39ea353/otSDKStub.js";
	script1.type = "text/javascript";
	script1.charset = "UTF-8";
	script1.setAttribute('data-domain-script', '018e9ff9-68c2-7c8b-964a-b2c3c39ea353');

	const script2 = document.createElement('script');
	script2.type = "text/javascript";
	script2.textContent = `
	function OptanonWrapper() { }
	`;

	document.body.appendChild(script1);
	document.body.appendChild(script2);
}


export const onRouteUpdate = () => {

	//const UIkit = require("uikit");
	const UIkit = window.UIkit;

	if(UIkit){
		UIkit.modal('#hamburger-menu-modal').hide();
	}

	if (process.env.NODE_ENV === "production") {
		if (window) {
			window.dataLayer = window.dataLayer || [];
			function gtag(){window.dataLayer.push(arguments);}
			gtag('js', new Date());

			gtag('config', 'G-VG6BJFWMY6');
		}

	}
	if (window && window.__sharethis__) {
		window.__sharethis__.initialize()
	}

}