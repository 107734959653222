import * as React from "react";
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { Script } from "gatsby"



export default function Layout( props ) {

	const [cDeco, setCDeco] = React.useState('');
	const [singleSSS, setSingleSSS] = React.useState('');

	React.useEffect(() => {

		if(props?.path === '/expertise/' || props?.path === '/' || props?.path === '/news-ideas/' || props?.path === '/locations/'){
			setCDeco('c-deco');
		} else {
			setCDeco('');
		}

		if( (props?.data?.wpSector && props?.data?.wpSector !== null) || props?.path === '/about/' || (props?.data?.wpService && props?.data?.wpService !== null) || (props?.data?.wpSolution && props?.data?.wpSolution !== null)){
			setSingleSSS('gray-bgr');
		} else {
			setSingleSSS('');
		}

	},[props?.path]);

	return (
		<>
			<noscript
				key="gtm-script-2"
				dangerouslySetInnerHTML={{
				__html: `
					<iframe title="GTM Script" src="https://www.googletagmanager.com/ns.html?id=GTM-N8XCKQMQ"
					height="0" width="0" style="display:none;visibility:hidden"></iframe>
				`
				}}
			/>
			<Script src="//cdn.bc0a.com/autopilot/f00000000239755/autopilot_sdk.js" />
			<main className={`animation-fade-container site-main ${cDeco} ${singleSSS}`}>
				<Header alias="header" location={props?.path} dataProps={props?.data} />
					{props.children}
				<Footer alias="footer" />
			</main>
		</>

	);
}

